import { tv, type VariantProps } from 'tailwind-variants'

export const buttonVariants = tv(
  {
    base: 'inline-flex items-center justify-center text-nowrap gap-x-3 disabled:pointer-events-none border transition-background',
    variants: {
      color: {
        primary: '',
        secondary: '',
        tertiary: ''
      },
      variant: {
        outlined: 'border',
        contained: ''
      },
      rounded: {
        md: 'rounded-lg',
        full: 'rounded-full'
      },
      fullWidth: {
        true: 'w-full'
      },
      size: {
        md: 'px-5 py-2 text-body1',
        lg: 'px-5 py-3 text-body1'
      }
    },
    compoundVariants: [
      {
        color: 'primary',
        variant: 'contained',
        class:
          'bg-black border-white/15 text-white disabled:bg-black/15 hover:bg-black/65'
      },
      {
        color: 'secondary',
        variant: 'contained',
        class:
          'bg-white border-black/15 text-black hover:bg-white/85 hover:border-black'
      },
      {
        color: 'tertiary',
        variant: 'contained',
        class: 'bg-[#b88c63] border-none text-black hover:brightness-110'
      },
      {
        color: 'primary',
        variant: 'outlined',
        class: 'border-white/15 hover:bg-black/50'
      },
      {
        color: 'secondary',
        variant: 'outlined',
        class: 'border-black/15 hover:bg-black/5'
      }
    ],
    defaultVariants: {
      color: 'primary',
      size: 'md',
      rounded: 'md',
      variant: 'contained',
      fullWidth: false
    }
  },
  {
    twMerge: false
  }
)

export type Variants = VariantProps<typeof buttonVariants>
